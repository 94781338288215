<template>
  <div class="my-lg ui-bg-transparent">
    <LazySlider
      :draggable="true"
      :autoplay="false"
      class="my-md mx-auto"
      :breakpoints="breakpoints"
      :total-slides="images?.length"
    >
      <swiper-slide v-for="(img, index) in images" :key="index" class="bg-white">
        <NuxtPicture
          loading="lazy"
          :src="img.src"
          quality="85"
          :alt="img.alt"
          @click="showImage(index)"
          :img-attrs="{ class: 'h-[500px] w-full object-cover cursor-pointer' }"
        />
      </swiper-slide>
    </LazySlider>

    <section
      class="fixed top-none flex w-screen h-screen bg-charcoal-darker bg-opacity-50 z-50"
      v-if="showOverlay"
      @click="showOverlay = false"
    >
      <div
        class="absolute w-full sm:w-4/5 md:w-3/4 lg:w-2/3 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        v-if="showOverlay"
      >
        <section class="relative">
          <NuxtPicture
            :src="images[overlayIndex].src"
            quality="85"
            :img-attrs="{ class: 'h-[800px] w-full object-cover rounded-sm' }"
          />
          <Icon
            name="times-thin"
            :size="36"
            class="absolute top-2xs right-2xs p-2xs rounded-round bg-mkm-blue-lighter cursor-pointer"
            @click="showOverlay = false"
          />
        </section>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import { SwiperSlide } from "swiper/vue";
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useGallery } from "../../composables/UseContent/useGallery";
import { ref } from "vue";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const gallery = computed(() => useGallery(page.value, component.value));

const images = computed(() => gallery.value.images);
const breakpoints = computed(() => gallery.value.breakpoints);

let overlayIndex = ref(0);
let showOverlay = ref(false);

function showImage(index: number) {
  overlayIndex.value = index;
  showOverlay.value = true;
}
</script>
